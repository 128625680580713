import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminFeedFilter,
  AdminUsersFilter,
  Dashboard,
  Employee,
  Expense,
  ExpenseFilter,
  ExpenseType,
  PAGE_SIZE,
  SalesforceCase,
  TransactionFeedItem,
  User
} from '@ems-gui/shared/util-core';
import { ApprovalEditColumns } from '@libs/shared/util-core/src/lib/models/approval-column-edit.model';
import { AuthHttpService } from '@sec-spec/lib-ng-oauth';
import { Observable, switchMap, take } from 'rxjs';
import { environment } from './environments/environment';
import { setExpenseOnSentry } from '@src/sentry/expense-processor';
import { SaveExpensePayload } from '@libs/shared/util-core/src/lib/models/expense.model';
import { OauthTokenService } from '@src/app/services/oauth-token.service';

const DEFAULT_PAGE_SIZE = 10;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiURL = environment.apiURL;
  sisApiURL = environment.sisApiURL;

  constructor(
    protected http: HttpClient,
    protected authHtpp: AuthHttpService,
    private tokenService: OauthTokenService
  ) {}

  private makeRawProtectedCall(
    method: string,
    url: string,
    data: any,
    headers: any,
    options: any
  ): Observable<any> {
    return this.tokenService.getAccessToken()
      .pipe(
        take(1),
        switchMap((token) => {
          if(!token) return this.tokenService.ofGoToLogin()
          return this.authHtpp.makeRawProtectedCall(
            method,
            url,
            data,
            { ...headers, Authorization: `Bearer: ${token}` },
            options
          )
        })
      );
  }

  _handleSortFilterParams(
    page: any,
    sort: any,
    filters: ExpenseFilter | AdminUsersFilter
  ) {
    const skip = page > 1 ? (page - 1) * PAGE_SIZE : 0;
    const filt: any = {};
    filt.skip = skip.toString();
    filt.limit = PAGE_SIZE.toString();

    if (sort.colId) {
      filt['sortParam'] = sort.colId;
    }

    if (sort.sort) {
      filt['sortOrder'] = sort.sort;
    }

    for (const key in filters) {
      if (filters['contacts']) {
        filt['contacts[]'] = filters['contacts'];
      } else if (
        Object.prototype.hasOwnProperty.call(filters, key) &&
        filters[key]
      ) {
        filt[key] = filters[key];
      }
    }

    return new HttpParams({
      fromObject: filt,
    });
  }

  saveOneExpenseReceipt(image: File) {
    const receipt: FormData = new FormData();
    receipt.append('receipt', image, image.name);
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/expenses/receipt`,
      receipt,
      {},
      {}
    );
  }

  getReceiptDataOcrOnly(image: File) {
    const receipt: FormData = new FormData();
    receipt.append('receipt', image, image.name);
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/expenses/receipt/ocr-only`,
      receipt,
      {},
      {}
    );
  }

  getReceiptDataByToken(token: string) {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/receipt?token=${token}`,
      {},
      {},
      {}
    );
  }

  // job code
  getJobCode() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/jobcodes`,
      {},
      {},
      {}
    );
  }

  // users
  getCurrentUser(): Observable<User> {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/oauth-server/user-info`,
      {},
      {},
      {}
    );
  }

  getExpenseCounts(): Observable<Dashboard> {
    const params = new HttpParams().append('isApprover', 'true');

    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/dashboard`,
      {},
      {},
      {
        params,
      }
    );
  }

  // expenses
  getOneExpenseReceipt(id): Observable<any> {
    // Adding HTTP headers that instruct the browser not to cache the response
    const headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    };
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/${id}/receipt`,
      {},
      headers,
      {}
    );
  }

  getOneExpense(id, getExpenseType?: string): Observable<any> {
    let url = `${this.apiURL}/expenses/${id}`;
    if(getExpenseType) {
      url+= `?type=${encodeURIComponent(getExpenseType)}`;
    }
    return this.makeRawProtectedCall(
      'get',
      url,
      {},
      {},
      {}
    );
  }

  getContact(id: number): Observable<{ count: number, users: Employee[] }> {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/all-users?contacts[]=${id}`,
      {},
      {},
      {}
    )
  }

  getDraftExpenses(): Observable<{ count: number, users: Employee[] }> {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/drafts?status=draft`,
      {},
      {},
      {}
    );
  }

  // (placeholder) need more than just pending
  getSubmittedExpenses(
    page: any,
    sort: any,
    filters: any
  ): Observable<{
    expenses: Expense[];
    totalCount: number;
  }> {
    const pageSize = filters.limit ? filters.limit : DEFAULT_PAGE_SIZE;
    const skip = page > 1 ? (page - 1) * pageSize : 0;
    let params;

    const updatedFilters: ExpenseFilter = {
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
      isBillable: '',
      vendor: '',
      type: '',
      paymentType: '',
      status: '',
      jobCode: '',
      salesforceId: '',
      ...filters,
    };

    if (updatedFilters.status) {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('status', updatedFilters.status)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId);
    } else {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('status', 'pending')
        .append('status', 'approved');
    }

    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/drafts`,
      {},
      {},
      {
        params,
      }
    );
  }

  getUnsubmittedExpenses(
    page: any,
    sort: any,
    filters: any
  ): Observable<{
    expenses: Expense[];
    totalCount: number;
  }> {
    const pageSize = filters.limit ? filters.limit : DEFAULT_PAGE_SIZE;
    const skip = page > 1 ? (page - 1) * pageSize : 0;
    let params;

    const updatedFilters: ExpenseFilter = {
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
      isBillable: '',
      vendor: '',
      type: '',
      paymentType: '',
      jobCode: '',
      salesforceId: '',
      status: '',
      ...filters,
    };

    if (updatedFilters.status) {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('status', updatedFilters.status)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId);
    } else {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('status', 'draft')
        .append('status', 'rejected')
    }
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/drafts`,
      {},
      {},
      {
        params,
      }
    );
  }

  getTrashedExpenses(
    page: any,
    sort: any,
    filters: any
  ): Observable<{
    expenses: Expense[];
    totalCount: number;
  }> {
    const pageSize = filters.limit ? filters.limit : DEFAULT_PAGE_SIZE;
    const skip = page > 1 ? (page - 1) * pageSize : 0;
    let params;

    const updatedFilters: ExpenseFilter = {
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
      isBillable: '',
      vendor: '',
      type: '',
      paymentType: '',
      status: '',
      jobCode: '',
      salesforceId: '',
      ...filters
    };

    if (updatedFilters.status) {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('status', updatedFilters.status)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('isTrash', 'true');
    } else {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('status', 'draft')
        .append('status', 'rejected')
        .append('isTrash', 'true');
    }

    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/drafts`,
      {},
      {},
      {
        params,
      }
    );
  }

  protected filterExpenseProperties(
    expense: Partial<Expense>,
    filterBy = [null, '']
  ) {
    const keysWhereNullIsAllowed = ['type'];
    return <Partial<Expense>>Object.keys(expense)
      .filter((k) => (!filterBy.includes(expense[k]) || keysWhereNullIsAllowed.includes(k)))
      .reduce((a, k) => ({ ...a, [k]: expense[k] }), {});
  }

  saveExpense(body: Partial<Expense>) {
    const updatedBody = this.filterExpenseProperties(body);
    const id = updatedBody.id;
    const method = id !== undefined ? 'put' : 'post';
    let url = `${this.apiURL}/expenses`;

    if(id === undefined) {
      url += '/submit';
    } else {
      url += `/${id}`;
      url += body.status === 'rejected' ? `/resubmit` : `/submit`;
    }

    delete updatedBody.id;
    delete updatedBody.status;

    return this.makeRawProtectedCall(
      method,
      url,
      updatedBody,
      {},
      {}
    );
  }

  submitNewExpense(expense: Partial<Expense>,  file: File, rotationAngle: number) {
    const updatedBody = this.filterExpenseProperties(expense, [null]);
    const id = expense.id;
    if (updatedBody.id) {
      delete updatedBody.id;
    }
    if (updatedBody.cardVerified) {
      delete updatedBody.cardVerified;
    }

    const payload: FormData = new FormData();
    if(file) {
      payload.append('receipt', file, file.name);
    }
    if(rotationAngle){
      payload.append('rotationAngle', rotationAngle.toString());
    }
    // Append all properties of updatedBody to the payload
    for (const key in updatedBody) {
      if (updatedBody.hasOwnProperty(key)) {
        const value = updatedBody[key];
        if (value !== null && value !== undefined) {
          payload.append(key, value.toString());
        }
      }
    }
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/expenses/submit`,
      payload,
      {},
      {}
    );
  }


  submitDraftExpense(saveExpensePayload, id: number) {
    const updatedBody = this.filterExpenseProperties(saveExpensePayload, [null]);

    const payload: FormData = new FormData();
    if(saveExpensePayload.file) {
      payload.append('receipt', saveExpensePayload.file, saveExpensePayload.file.name);
    }

    // Append all properties of updatedBody to the payload
    for (const key in updatedBody) {
      if (updatedBody.hasOwnProperty(key)) {
        const value = updatedBody[key];
        if (value !== null && value !== undefined) {
          payload.append(key, value.toString());
        }
      }
    }
    let url = `${this.apiURL}/expenses`;

    url += `/${id}`;
      url += `/submit`;
    return this.makeRawProtectedCall(
      'put',
      url,
      payload,
      {},
      {}
    );
  }


  multiEditSaveExpense(body) {
    const updatedExpenses = {
      expenses: body.ids,
      changes: body.expense
    }

    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/update-expenses`,
      updatedExpenses,
      {},
      {}
    );
  }

  saveExpenses(expenses) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/submit`,
      expenses,
      {},
      {}
    );
  }

  resubmitExpenses(expenses) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/resubmit`,
      expenses,
      {},
      {}
    );
  }

  saveDraft(body: Partial<Expense> ) {
    const updatedBody = this.filterExpenseProperties(body, [null]);
    const id = body.id;
    if (updatedBody.id) {
      delete updatedBody.id;
    }
    if (updatedBody.cardVerified) {
      delete updatedBody.cardVerified;
    }
    if ('paymentMethod' in updatedBody) {
      updatedBody.paymentType = updatedBody['paymentMethod'];
    }
    setExpenseOnSentry(body);
    if (id) {
      return this.makeRawProtectedCall(
        'put',
        `${this.apiURL}/expenses/${id}`,
        updatedBody,
        {},
        {}
      );
    } else {
      return this.makeRawProtectedCall(
        'post',
        `${this.apiURL}/expenses`,
        updatedBody,
        {},
        {}
      );
    }
  }


  saveDraftWithReceipt(body: Partial<Expense>,  file: File, rotationAngle: number ) {
    const updatedBody = this.filterExpenseProperties(body, [null]);
    const id = body.id;
    if (updatedBody.id) {
      delete updatedBody.id;
    }
    if (updatedBody.cardVerified) {
      delete updatedBody.cardVerified;
    }
    if ('paymentMethod' in updatedBody) {
      updatedBody.paymentType = updatedBody['paymentMethod'];
    }
    const payload: FormData = new FormData();
    if(file){
      payload.append('receipt', file, file.name);
    }
    if(rotationAngle){
      payload.append('rotationAngle', rotationAngle.toString());
    }
    // Append all properties of updatedBody to the payload
    for (const key in updatedBody) {
      if (updatedBody.hasOwnProperty(key)) {
        const value = updatedBody[key];
        if (value !== null && value !== undefined) {
          payload.append(key, value.toString());
        }
      }
    }

    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/expenses/save`,
      payload,
      {},
      {}
    );
  }

  saveExpenseWithReceipt(body: Partial<SaveExpensePayload>) {
    const payload: FormData = new FormData();
    Object.keys(body).forEach((key) => {
      if (key === 'file') {
        payload.append('receipt', body.file, body.file.name);
      } else {
        payload.append(key, body[key]);
      }
    });

    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${body.id}/save`,
      payload,
      {},
      {}
    );
  }

  deleteExpenses(expenses) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/trash/void`,
      expenses,
      {},
      {}
    );
  }

  trashExpenses(expenses) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/trash`,
      expenses,
      {},
      {}
    );
  }

  restoreExpenses(expenses) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/trash/restore`,
      expenses,
      {},
      {}
    );
  }

  emptyTrash() {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/trash/empty`,
      {},
      {},
      {}
    );
  }

  getPendingApprovals(
    page: any,
    sort: any,
    filters: any
  ): Observable<{
    expenses: Expense[];
    totalCount: number;
  }> {
    const pageSize = filters.limit ? filters.limit : DEFAULT_PAGE_SIZE;
    const skip = page > 1 ? (page - 1) * pageSize : 0;
    let params;

    const updatedFilters: ExpenseFilter = {
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
      isBillable: '',
      vendor: '',
      type: '',
      paymentType: '',
      status: '',
      jobCode: '',
      salesforceId: '',
      isSingle: '',
      employee: '',
      isOverdue: '',
      fraudulent: '',
      ...filters,
    };
    const overdue = updatedFilters.isOverdue
      ? updatedFilters.isOverdue.toString()
      : '';

    if (updatedFilters.isBillable !== '' && updatedFilters.isSingle !== '') {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('status', 'pending')
        .append('isAwaitingReview', '1')
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('isBillable', updatedFilters.isBillable)
        .append('paymentType', updatedFilters.paymentType)
        .append('isSingle', updatedFilters.isSingle)
        .append('vendor', updatedFilters.vendor)
        .append('type', updatedFilters.type)
        .append('employee', updatedFilters.employee)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('isOverdue', overdue)
        .append('fraudulent', updatedFilters.fraudulent);
    } else if (
      updatedFilters.isBillable !== '' &&
      updatedFilters.isSingle === ''
    ) {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('status', 'pending')
        .append('isAwaitingReview', '1')
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('isBillable', updatedFilters.isBillable)
        .append('paymentType', updatedFilters.paymentType)
        .append('vendor', updatedFilters.vendor)
        .append('type', updatedFilters.type)
        .append('employee', updatedFilters.employee)
        .append('isSingle', updatedFilters.isSingle)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('overdue', overdue)
        .append('fraudulent', updatedFilters.fraudulent);
    } else if (
      updatedFilters.isBillable === '' &&
      updatedFilters.isSingle !== ''
    ) {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('status', 'pending')
        .append('isAwaitingReview', '1')
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('isSingle', updatedFilters.isSingle)
        .append('vendor', updatedFilters.vendor)
        .append('type', updatedFilters.type)
        .append('employee', updatedFilters.employee)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('overdue', overdue)
        .append('fraudulent', updatedFilters.fraudulent);
    } else {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('status', 'pending')
        .append('isAwaitingReview', '1')
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('vendor', updatedFilters.vendor)
        .append('employee', updatedFilters.employee)
        .append('isSingle', updatedFilters.isSingle)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('overdue', overdue)
        .append('fraudulent', updatedFilters.fraudulent);
    }

    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/approvals`,
      {},
      {},
      {
        params,
      }
    );
  }

  // expense types
  getExpenseType(): Observable<ExpenseType[]> {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expense-types`,
      {},
      {},
      {}
    );
  }

  // reports
  verifyExpenses(body) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/reports/verify`,
      body,
      {},
      {}
    );
  }

  undoVerifyExpenses(body) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/reports/verify/undo`,
      body,
      {},
      {}
    );
  }

  getAllPendingApprovals(): Observable<{
    expenses: Expense[];
    totalCount: number;
  }> {
    const params = new HttpParams().append('status', 'pending');

    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/approvals`,
      {},
      {},
      {
        params,
      }
    );
  }

  getReviewedApprovals(
    page?: any,
    sort?: any,
    filters?: any
  ): Observable<{
    expenses: Expense[];
    totalCount: number;
  }> {
    const pageSize = filters.limit ? filters.limit : DEFAULT_PAGE_SIZE;
    const skip = page > 1 ? (page - 1) * pageSize : 0;
    let params;

    const updatedFilters: ExpenseFilter = {
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
      isBillable: '',
      vendor: '',
      type: '',
      paymentType: '',
      status: '',
      jobCode: '',
      salesforceId: '',
      isSingle: '',
      employee: '',
      ...filters,
    };

    if (updatedFilters.status) {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('status', updatedFilters.status)
        .append('employee', updatedFilters.employee)
        .append('isSingle', updatedFilters.isSingle)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('isAwaitingReview', '0');
    } else {
      params = new HttpParams()
        .append('skip', skip.toString())
        .append('limit', pageSize.toString())
        .append('sortParam', sort.colId)
        .append('sortOrder', sort.sort.toUpperCase())
        .append('startDate', updatedFilters.startDate)
        .append('endDate', updatedFilters.endDate)
        .append('vendor', updatedFilters.vendor)
        .append('minAmount', updatedFilters.minAmount)
        .append('maxAmount', updatedFilters.maxAmount)
        .append('paymentType', updatedFilters.paymentType)
        .append('type', updatedFilters.type)
        .append('isBillable', updatedFilters.isBillable)
        .append('employee', updatedFilters.employee)
        .append('isSingle', updatedFilters.isSingle)
        .append('jobCode', updatedFilters.jobCode)
        .append('customCase', updatedFilters.salesforceId)
        .append('status', 'approved')
        .append('status', 'rejected')
        .append('status', 'pending')
        .append('isAwaitingReview', '0');
    }
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/approvals`,
      {},
      {},
      {
        params,
      }
    );
  }

  getFavoriteJobCode(): Observable<any> {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/favorites/jobcodes`,
      {},
      {},
      {}
    );
  }

  saveFavorite(data): Observable<any> {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/users/favorites/jobcodes`,
      data,
      {},
      {}
    );
  }

  unfavorite(id): Observable<any> {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/users/favorites/jobcodes/${id}`,
      {},
      {},
      {}
    );
  }

  getActivity(id: number) {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/${id}/activity`,
      {},
      {},
      {}
    );
  }

  getAllUsers() {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/employees/all?status=active`,
      {},
      {},
      {}
    );
  }

  getAllPersons() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/all-users`,
      {},
      {},
      {}
    );
  }

  getEmployees(
    page?: any,
    sort?: any,
    filters?: AdminUsersFilter
  ): Observable<{
    users: Employee[];
    totalCount: number;
  }> {
    const params = this._handleSortFilterParams(page, sort, filters);
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/all-users`,
      {},
      {},
      {
        params,
      }
    );
  }

  getEmployeesBySearchString(
    filters: AdminUsersFilter
  ): Observable<{
    users: Employee[];
    totalCount: number;
  }> {
    const params = new HttpParams().append('q', filters.q);
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/all-users`,
      {},
      {},
      {
        params,
      }
    );
  }

  getPermissions(id: number) {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/permission/user-permissions/spense/0.1.0/${id}`,
      {},
      {},
      {}
    );
  }

  getAllPermissions() {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/permission/user-permissions/spense/0.1.0`,
      {},
      {},
      {}
    );
  }

  deleteNote(id: number) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/expenses/activity/${id}`,
      {},
      {},
      {}
    );
  }

  getFavoritePersons() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/favorites/employees`,
      {},
      {},
      {}
    );
  }

  saveFavoritePerson(body) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/users/favorites/employees`,
      body,
      {},
      {}
    );
  }

  unfavoritePerson(id: number) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/users/favorites/employees/${id}`,
      {},
      {},
      {}
    );
  }

  getAllAlternateEmails() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/emails`,
      {},
      {},
      {}
    );
  }

  saveAlternateEmail(email) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/users/emails`,
      { email },
      {},
      {}
    );
  }

  deleteAlternateEmail(id) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/users/emails/${id}`,
      {},
      {},
      {}
    );
  }

  adminGetAlternateEmails(id) {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/admin/users/${id}/email`,
      {},
      {},
      {}
    );
  }

  adminAddAlternateEmail(email) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/admin/users/${email.id}/email`,
      { email: email.email },
      {},
      {}
    );
  }

  adminUpdateAlternateEmailNotifications(update) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/admin/users/email/${update.id}`,
      {
        user: update.user,
        enabled: update.enabled,
      },
      {},
      {}
    );
  }

  updateAlternateEmailNotifications(update) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/users/emails/${update.id}`,
      {
        enabled: update.enabled,
      },
      {},
      {}
    );
  }

  getFavoriteSalesforceCases() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/favorites/customcases`,
      {},
      {},
      {}
    );
  }

  saveFavoriteSalesforceCase(body) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/users/favorites/customcases`,
      body,
      {},
      {}
    );
  }

  unfavoriteSalesforceCase(id: number) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/users/favorites/customcases/${id}`,
      {},
      {},
      {}
    );
  }

  getSalesforceCase() {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/custom-cases/ems`,
      {},
      {},
      {}
    );
  }

  public getSalesforceCaseById(externalId: number) {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/custom-cases/${externalId}`,
      {},
      {},
      {}
    ) as Observable<SalesforceCase>;
  }

  getProxies(id: number) {
    const params = new HttpParams().append('user', id.toString());
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/proxy`,
      {},
      {},
      {
        params,
      }
    );
  }

  getUsersForProxy(id: number) {
    const params = new HttpParams().append('proxy', id.toString());
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/proxy`,
      {},
      {},
      {
        params,
      }
    );
  }

  addProxy(body) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/users/proxy`,
      body,
      {},
      {}
    );
  }

  removeProxy(id) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/users/proxy/${id}`,
      {},
      {},
      {}
    );
  }

  getNotificationSetting() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/email/notification-setting`,
      {},
      {},
      {}
    );
  }

  putNotificationSetting({ body }) {
    const id = body.id;
    const updatedBody = {
      ...body,
    };
    delete updatedBody.id;
    delete updatedBody.type;
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/email/notification-setting/${id}`,
      updatedBody,
      {},
      {}
    );
  }

  getUsersHasProxyStatus() {
    const params = new HttpParams().append('hasProxy', 'true');
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/proxy/search`,
      {},
      {},
      {
        params,
      }
    );
  }

  getUsersIsProxyStatus() {
    const params = new HttpParams().append('isProxy', 'true');
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/users/proxy/search`,
      {},
      {},
      {
        params,
      }
    );
  }
  getMileage() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/admin/mileage`,
      {},
      {},
      {}
    );
  }

  updateMileage(body) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/admin/mileage`,
      body,
      {},
      {}
    );
  }

  getFeedErrorUsers() {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/admin/feed/email`,
      {},
      {},
      {}
    );
  }

  addFeedErrorUsers(body) {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/admin/feed/email`,
      body,
      {},
      {}
    );
  }

  deleteFeedErrorUser(id) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/admin/feed/email/${id}`,
      {},
      {},
      {}
    );
  }

  getTransactionsFeedItems(
    page: number,
    sort: {
      colId: string;
      sort: string;
    },
    filters: AdminFeedFilter
  ): Observable<{
    feeds: TransactionFeedItem[];
    totalCount: number;
  }> {
    const params = this._handleSortFilterParams(page, sort, filters);

    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/admin/all-feed`,
      {},
      {},
      {
        params,
      }
    );
  }

  getTransactionFeedDownloadUrl(id: number) {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/admin/feed/${id}/link`,
      {},
      {},
      {}
    );
  }

  getFeedItemFile(url): Observable<unknown> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  getMileageReimbursement(body): Observable<any> {
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/expenses/mileage`,
      body,
      {},
      {}
    );
  }

  saveMileageDraft(body: Partial<Expense>) {
    const updatedBody = this.filterExpenseProperties(body, [null]);
    const id = updatedBody.id;

    if (updatedBody.id) {
      delete updatedBody.id;
    }
    delete updatedBody.status;
    if (id) {
      return this.makeRawProtectedCall(
        'put',
        `${this.apiURL}/expenses/mileage/${id}`,
        updatedBody,
        {},
        {}
      );
    } else {
      return this.makeRawProtectedCall(
        'post',
        `${this.apiURL}/expenses`,
        updatedBody,
        {},
        {}
      );
    }
  }

  submitMileageDraft(body: Partial<Expense>) {
    return this.saveExpense(body);
  }

  saveItemizedExpenses(body): Observable<any> {
    const id = body.id;
    const updatedBody = {
      ...body,
    };
    delete updatedBody.id;
    if (body.id) {
      return this.makeRawProtectedCall(
        'post',
        `${this.apiURL}/expenses/${id}/items`,
        updatedBody,
        {},
        {}
      );
    }
  }

  getItemizedExpenses(id): Observable<any> {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/${id}/items`,
      {},
      {},
      {}
    );
  }

  updateItemizedExpenses(body): Observable<any> {
    const id = body.id;
    const updatedBody = {
      ...body,
    };
    delete updatedBody.id;
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${id}/reitemize`,
      updatedBody,
      {},
      {}
    );
  }

  undoItemization(id): Observable<any> {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/expenses/${id}/items`,
      {},
      {},
      {}
    );
  }

  removeReceipt(id): Observable<any> {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/expenses/${id}/receipt`,
      {},
      {},
      {}
    );
  }

  rotateReceipt({ id, angle }): Observable<any> {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${id}/receipt/rotate`,
      { angle },
      {},
      {}
    );
  }

  rotateReceiptApprover({ id, angle }): Observable<any> {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${id}/receipt/rotate`,
      { angle },
      {},
      {}
    );
  }

  sendApprovalToJobCode(body): Observable<any> {
    const id = body.id;
    const updatedBody = {
      ...body,
    };
    delete updatedBody.id;
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${id}/jobcode`,
      updatedBody,
      {},
      {}
    );
  }

  approvalEdit(body: ApprovalEditColumns): Observable<any> {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/approval-edit`,
      body,
      {},
      {}
    );
  }

  undoSendApprovalToJobCode(id): Observable<any> {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${id}/jobcode/undo`,
      {},
      {},
      {}
    );
  }

  getReceiptFileUrls(expenses: number[], action: string, id_type: string): Observable<any> {
    const params = new HttpParams().append('id_type', id_type);
    return this.makeRawProtectedCall(
      'post',
      `${this.apiURL}/expenses/receipt/manager`,
      {expenses, action},
      {},
      {params}
    );
  }

  getEmailReceipt(): Observable<any> {
    return this.makeRawProtectedCall(
      'get',
      `${this.apiURL}/expenses/receipts/emails`,
      {},
      {},
      {}
    );
  }

  getReceiptFile(url): Observable<any> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  getTravelTicketByName(name: string) {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/travel/tickets/${name}`,
      {},
      {},
      {}
    );
  }

  getTravelTicketByDetailName(name: string) {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/travel/details/${name}`,
      {},
      {},
      {}
    );
  }

  getTravelTicketDetailsBySaleforceId(salesforceId: string) {
    return this.makeRawProtectedCall(
      'get',
      `${this.sisApiURL}/travel/details/id/${salesforceId}`,
      {},
      {},
      {}
    );
  }

  removeTravelTicketDetailFromExpense(expenseId: number) {
    return this.makeRawProtectedCall(
      'delete',
      `${this.apiURL}/expenses/${expenseId}/travelDetails`,
      {},
      {},
      {}
    );
  }

  recallExpense(expenseId: number) {
    return this.makeRawProtectedCall(
      'put',
      `${this.apiURL}/expenses/${expenseId}/recall`,
      {},
      {},
      {}
    );
  }
}
