import { CurrencyPipe, DatePipe, formatDate } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DatepickerComponent } from '@ems-gui/expense/ui-web-components';
import {
  environment,
  UtilsService
} from '@ems-gui/expense/util-web-infrastructure';
import {
  ConvertToDollarsPipe,
  CustomValidators,
  Expense,
  ExpenseType,
  JobCode,
  MAX_CHARACTER_LENGTH,
  OnChange,
  PAYMENT_OPTIONS
} from '@ems-gui/shared/util-core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ExpenseService } from '@src/app/services/expense.service';
import { Subject, Observable } from 'rxjs';
import { debounceTime, switchMap, take, takeUntil, tap } from "rxjs/operators";
import {
  selectedProxyId,
  selectUserCanAssignTDR,
  selectProxyUserCanAssignTDR,
  State,
  selectUserHasCreditCard,
  selectAllExpenseTypesForPersonalExpense,
  selectReceiptData,
  selectReceiptDataByToken,
  selectExpenseWithAssignedTravelTicket,
  DraftActions,
  NewActions
} from '@ems-gui/expense/util-web-ngrx';
import { Store, select } from '@ngrx/store';
import { FormManagerService } from '@src/app/services/form-manager.service';
import moment from 'moment';
import { ApiService } from "@ems-gui/expense/util-web-infrastructure";

@Component({
  selector: 'ems-expense-form-edit',
  templateUrl: './expense-form-edit.component.html',
  styleUrls: ['./expense-form-edit.component.scss'],
  providers: [ConvertToDollarsPipe, CurrencyPipe, DatePipe],
})
export class ExpenseFormEditComponent implements OnInit, OnDestroy {
  @Input() public expenseTypes: ExpenseType[];
  isOcrMismatch;
  creditCardUser$ = this.store$.select(selectUserHasCreditCard);
  canAssignTDR$ = this.store$.pipe(select(selectUserCanAssignTDR));
  proxyCanAssignTDR$ = this.store$.pipe(select(selectProxyUserCanAssignTDR));
  proxyId$ = this.store$.pipe(select(selectedProxyId));
  unsubscribe$ = new Subject<void>();
  paymentOptions = PAYMENT_OPTIONS;
  readonly maxLength = MAX_CHARACTER_LENGTH;
  dismissing = false;
  showNote = false;
  continueFraudulentSubmission$: Observable<any>;
  showCompanyNote = false;
  showSalesforce = false;
  showFraud = false;
  showBillable = false;
  additionalInfo = [];
  url = `none`;
  method = 'put';
  smallMaxScreenWidth = 767;
  private isProgrammaticChange = false;
  @Input() isMobileView = false;
  screenWidth: number;
  public showAdditionalDetails = false;
  @Output() public resetForm = new EventEmitter<FormGroup>();
  @Output() public saveForm = new EventEmitter<FormGroup>();

  @OnChange(function (this: ExpenseFormEditComponent, expense) {
    this.updateFormValues(expense);
  })
  /**
   * Inputs
   */
  @Input() expense;
  @Input() jobCodes: JobCode[];
  @Input() favoriteJobCodes: JobCode[];
  @Input() salesforceCases;
  @Input() favoriteSalesforceCases;
  @Input() itemizationStatus;
  @Input() isTrashed = null;
  @Input() proxy;
  @Input() submitted;
  @ViewChild(DatepickerComponent) datepicker: DatepickerComponent;

  @OnChange(function (this: ExpenseFormEditComponent, dismiss) {
    this.onDismiss(dismiss);
  })
  @Input() dismiss: boolean;
  public maxDate = new Date();

  /**
   * Outputs
   */
  @Output() favoriteJobCode: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteSalesforce: EventEmitter<any> = new EventEmitter<any>();
  @Output() expenseFormChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() amountChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() amountStatusChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() deleteNote: EventEmitter<any> = new EventEmitter<string>();
  @Output() removeReceipt: EventEmitter<any> = new EventEmitter<any>();
  @Output() receiptData: EventEmitter<any> = new EventEmitter<any>();
  @Output() rotateReceipt: EventEmitter<any> = new EventEmitter<any>();
  @Output() receiptUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileSizeError: EventEmitter<any> = new EventEmitter<any>();

  @Input() public formId = 'edit-expense';
  /**
   * Form
   */
  form: FormGroup = this.fb.group({
     ...this.expenseService.form(),
     receiptRemoved: [false]
  });
  fileData: { file: File; rotationAngle: number };
  receiptDataOnUpload;

  constructor(
    private expenseService: ExpenseService,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private convertToDollarsPipe: ConvertToDollarsPipe,
    private utils: UtilsService,
    private ref: ChangeDetectorRef,
    private store$: Store<State>,
    private formManager: FormManagerService,
    private apiService: ApiService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    this.isMobileView = this.screenWidth > this.smallMaxScreenWidth;
  }
  get vendor() {
    return this.form.get('vendor');
  }

  get address() {
    return this.form.get('address');
  }

  get amount() {
    return this.form.get('amount');
  }

  get transactionDate() {
    return this.form.get('transactionDate');
  }

  get fraudulent() {
    return this.form.get('fraudulent');
  }

  get isBillable() {
    return this.form.get('isBillable');
  }

  get message() {
    return this.form.get('message');
  }

  get jobCode() {
    return this.form.get('jobCode');
  }

  get salesforceId() {
    return this.form.get('salesforceId');
  }

  get paymentType() {
    return this.form.get('paymentType');
  }

  get type() {
    return this.form.get('type');
  }

  get description() {
    return this.form.get('description');
  }

  get inputError() {
    const amount = this.amount;
    return (
      ((amount.touched || this.submitted) && amount.errors?.amountNotValid) ||
      (amount.errors?.required && this.submitted)
    );
  }

  get vendorIsRequired() {
    const vendor = this.vendor;

    return vendor.invalid && this.submitted;
  }

  get dateIsRequired() {
    const date = this.transactionDate;
    return date.invalid && this.submitted;
  }

  get expenseTypeIsRequired() {
    const type = this.type;

    return type.invalid && this.submitted;
  }

  get descriptionIsRequired() {
    const description = this.description;

    return description.invalid && this.submitted;
  }

  get jobCodeIsRequired() {
    const jobCode = this.jobCode;

    return jobCode.invalid && this.submitted;
  }

  get amountIsRequired() {
    const amount = this.amount;
    return amount.errors?.required && this.submitted;
  }

  get amountIsInvalid() {
    const amount = this.amount;
    return (amount.touched || this.submitted) && amount.errors?.amountNotValid;
  }

  get fraudulentInvalid() {
    const fraudulent = this.fraudulent;
    return fraudulent.invalid && this.submitted;
  }

  get amountRequiredForItemization() {
    const amount = this.amount;

    return (
      (amount.invalid || amount.errors?.required) && !this.itemizationStatus
    );
  }

  get maxAmountExceeded() {
    const amount = this.amount;
    return (amount.dirty || amount.touched) && amount.errors?.maxAmountExceeded;
  }

  ngOnInit() {
    this.store$.select(selectAllExpenseTypesForPersonalExpense).pipe(
      take(1),
      tap((expenseTypes) => this.expenseTypes = expenseTypes)
    ).subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(300), // Emit changes only after 200ms of no further changes
        tap((currentValue) => {
          try {
            // Handle the form changes as needed
            const form = this._handleForm(currentValue);
            this.expenseFormChange.emit(form);

          } catch (error) {
            console.error('Error handling form value:', error);
          }
        })
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.statusChange.emit(valid);
        })
      )
      .subscribe();

    this.form
      .get('amount')
      .statusChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.amountStatusChange.emit(valid);
        })
      )
      .subscribe();
    this.form
      .get('amount')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          this.amountChange.emit(this.utils.dollarsToCents(value));
        })
      )
      .subscribe();

    this.form
      .get('type')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          this.handleExpenseTypeChange(value);
        })
      )
      .subscribe();

    this.form
      .get('jobCode')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          this.handleJobCodeChange(value);
        })
      )
      .subscribe();

    this.form
      .get('salesforceId')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          const filteredCases = this.salesforceCases.filter((c) => c.id === value);
          // Find the matching jobCode record if custom case ahs approval jobCode
          if(filteredCases.length > 0 && filteredCases[0].expense_approval_job_code !== null) {
            const approval_jobCode = this.jobCodes.filter
              ((j) => j.id === filteredCases[0].expense_approval_job_code
            );
            // if the approval jobCode is part of the dropdown assign jobCode to expense
            if(approval_jobCode.length > 0) {
              this.jobCode.setValue(approval_jobCode[0].id);
            }
          }
        })
      )
      .subscribe();

    this.store$
      .pipe(
        select(selectReceiptData),
        takeUntil(this.unsubscribe$),
        tap((receiptData: Partial<Expense>) => {
          if(receiptData && Object.keys(receiptData).length !== 0){
            this.setFormValueFromReceipt(receiptData);
            this.receiptDataOnUpload = receiptData;
            this.receiptData.emit({
              ...this.fileData,
              token: receiptData.token
            });
          }
        })
      )
      .subscribe();

    this.isOcrMismatch = this.expense.ocrMismatch;

    const currentForm = this._handleForm(this.form.value);
    const amount = currentForm.amount;
    this.amountChange.emit(+amount);
    this.statusChange.emit(this.form.status === 'VALID');
    this.amountStatusChange.emit(this.form.get('amount').status === 'VALID');
    this.handleExpenseTypeChange(this.type.value);
    this.handleJobCodeChange(this.jobCode.value);
    this.updateFormValues(this.expense);
    this.expenseFormChange.emit(currentForm);
    // You have found how to set the value!
    if (this.showBillable) this.isBillable.setValue(this.expense.isBillable);
    this.onResize();
    this.formManager.register(this.formId, this.form);

  }

  updateFormValues(expense) {
    if (!this.form) return;

    if (expense?.message) {
      this.showNote = true;
    }

    if (expense?.salesforceId) {
      this.showSalesforce = true;
    }

    if (expense?.extraInfo && expense.extraInfo !== '') {
      const extraInfo = expense.extraInfo;
      this.additionalInfo = extraInfo;
    }

    const hasChargebackPaymentTypes =
      expense.paymentType === 'company' ||
      expense.paymentType === 'personal' ||
      expense.paymentType === 'mileage';

    if (expense.paymentType && hasChargebackPaymentTypes && this.amount) {
      this.amount.setValidators([
        Validators.required,
        CustomValidators.validCompanyAmount,
      ]);
      this.amount.updateValueAndValidity();
    }

    if (
      expense.paymentType &&
      expense.id &&
      expense.paymentType === 'company' &&
      expense.cardVerified
    ) {
      this.url =
        expense.id &&
        `${environment.apiURL}/expenses/${expense.id}/receipt/verify-with-ocr`;
    } else if (expense.id) {
      this.url = `${environment.apiURL}/expenses/${expense.id}/receipt`;
    }

    const expenseType = expense['type'];
    const jobCode = expense['jobCode'];
    const salesforceId = expense['salesforceId'];

    Object.keys(expense).forEach((property) => {
      const propertyIsOther =
        property &&
        property !== 'type' &&
        property !== 'amount' &&
        property !== 'jobCode' &&
        property !== 'salesforceId' &&
        this.form &&
        this.form.get(property);
      const propertyIsExpenseType =
        property &&
        property === 'type' &&
        expenseType &&
        expenseType.id &&
        this.form;
      const propertyIsAmount = property && property === 'amount' && this.form;
      const propertyIsJobCode =
        property && property === 'jobCode' && jobCode && this.form;
      const propertyIsSalesforceId =
        property && property === 'salesforceId' && salesforceId && this.form;

      if (propertyIsOther) {
        this.form.get(property).setValue(expense[property]);
      } else if (propertyIsExpenseType) {
        this.form.get('type').setValue(expenseType.id);
      } else if (propertyIsAmount) {
        const dollars = this.convertToDollarsPipe.transform(expense['amount']);
        const updatedAmount = this.currencyPipe.transform(dollars);
        this.form.get('amount').setValue(updatedAmount);
      } else if (propertyIsJobCode) {
        this.form.get('jobCode').setValue(jobCode);
      } else if (propertyIsSalesforceId) {
        this.form.get('salesforceId').setValue(salesforceId.toString());
      }
    });
  }

  handleExpenseTypeChange(value: string | number) {
    // extract name of expenseType since formControl has id of the expense
    // I had to use == and not === since the value from valueChanges
    // function comes in as string even though it was number
    if(value && (value !== '0' && value !== 0)) {
      this.type.setErrors(null);
      const expenseType = this.expenseTypes.find(({ id }) => id === +value);
      const showFraudPre = this.showFraud;

      if(expenseType && expenseType.name) {
        // if the expensetype was valid, and showFraud changed to false,
        // when it was true before, expense's fraud value must be reset
        this.showFraud = expenseType.name.toLowerCase().includes('fraud');
        if (this.showFraud != showFraudPre) {
          if (showFraudPre) {
            this.resetFormAfterUncheckingFraud();
            this.fraudulent.setValue(false);
          }
        }
      } else {
        // if the expenseType is not valid, set the expenseType to null.
        this.type.setValue(null);
      }

      if(this.showFraud) {
        if(this.fraudulent.value === false) {
          // set fraudulent formControl to invalid
          this.fraudulent.setErrors({ incorrect: true });
        }
        this.fraudulent.setValidators(Validators.requiredTrue);
      } else {
        this.fraudulent.clearValidators();
        this.fraudulent.setErrors(null);
      }
    } else {
      this.type.setErrors({incorrect: true});
    }

  }

  handleJobCodeChange(value: any) {
    if(value && (value !== 0 && value !== '0')) {
      this.jobCode.setErrors(null);
      this.showBillable = true;
      const jc = this.jobCodes.find(p => p.id == value);
      if(jc && jc.company && jc.company.billable) {
        this.isBillable.setValue(jc.company.billable);
      }

      this._handleForm(this.form.value);
    } else {
      this.jobCode.setErrors({ incorrect: true });
      this.jobCode.setValue(0, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.formManager.deregister(this.formId);
    this.store$.dispatch(DraftActions.clearReceiptDataByToken());
    this.store$.dispatch(NewActions.clearReceiptData());
  }

  onFavorite(id) {
    if (id) {
      this.favoriteJobCode.emit(id);
    }
  }

  onFavoriteSalesforceCase(id) {
    if (id) {
      this.favoriteSalesforce.emit(id);
    }
  }

  onToggleNote() {
    if (!this.isTrashed) {
      if (this.showNote) {
        this.form.get('message').setValue(null);
      }

      // get form to sync
      const form = this._handleForm(this.form.value);
      this.expenseFormChange.emit(form);
      this.showNote = !this.showNote;

    }
  }

  onToggleSalesforce() {
    if (!this.isTrashed) {
      if (this.fraudulent.value === true) {
        this.form
          .get('salesforceId')
          .disable({ onlySelf: true, emitEvent: true });
        this.form.get('salesforceId').updateValueAndValidity();
        return;
      }
      this.showSalesforce = !this.showSalesforce;
      if (!this.showSalesforce) {
        this.form.get('salesforceId').setValue(null);
      }
    }
  }

  receiptRequired() {
    // Receipt not required if its fraudulent expense
    if(this.showFraud ) {
      return false;
    } else {
      return this.utils.checkIfForeignTransactionType(this.expense, this.expenseTypes);
    }
  }

  getTimestamp(date: number) {
    const beginningTimeToday = new Date(
      formatDate(new Date(), 'mediumDate', 'en-US')
    ).getTime();
    const endingTimeToday = beginningTimeToday + 86399999;
    const beginningTimeYesterday = beginningTimeToday - 86400000;
    const endingTimeYesterday = beginningTimeToday - 1;

    if (date >= beginningTimeToday && date <= endingTimeToday) {
      return `Today at ${formatDate(date, 'shortTime', 'en-US')}`;
    } else if (date >= beginningTimeYesterday && date <= endingTimeYesterday) {
      return `Yesterday at ${formatDate(date, 'shortTime', 'en-US')}`;
    } else {
      return formatDate(date, 'shortDate', 'en-US');
    }
  }

  private _handleForm(value) {
    const form = { ...value };

    form.transactionDate = form.transactionDate
      ? form.transactionDate
      : this.expense.transactionDate;

    form.type =
      typeof form.type === 'string' ? parseInt(form.type, 10) : form.type;

    form.amount =
      form.amount && !this.expense.parent && this.amount.valid
        ? this.utils.dollarsToCents(form.amount)
        : null;

    if (form.amount === null) {
      delete form.amount;
    }

    if (this.expense && this.expense.id) {
      form.id = this.expense.id;
    }


    if (form.message === null) {
      form.activityId = null;
    } else if (this.expense && this.expense.activityId) {
      form.activityId = this.expense.activityId;
    }

    if (this.expense && form.message && this.expense.status === 'rejected') {
      form.action = 'resubmitted';
    }

    if (this.expense && this.expense.status) {
      form.status = this.expense.status;
    }

    if (this.expense && form.salesforceId !== '0') {
      form.salesforceId = +form.salesforceId;
    }

    if (this.expense && this.expense.jobCode && this.expense.jobCode.id) {
      const formJobCode = this.form.get('jobCode').value;
      form.jobCode = formJobCode
          ? formJobCode
          : this.expense.jobCode.id.toString();
    }

    if (this.expense && this.expense.updatedAt) {
      form.updatedAt = this.expense.updatedAt;
    }

    if (this.expense && this.expense.cardVerified) {
      form.cardVerified = this.expense.cardVerified;
      this.transactionDate.disable({ onlySelf: true, emitEvent: true });
    }

    if (this.isTrashed) {
      this.transactionDate.disable({ onlySelf: true, emitEvent: true });
      this.jobCode.disable({ onlySelf: true, emitEvent: true });
    }

    if (this.expense && this.expense.parent) {
      form.parent = this.expense.parent;
    }

    /**
     * when there is a company credit card and an expense that is not verified
     * this is a special case that makes the form not valid and we send a status change
     * manually
     */
    if (form.paymentType === 'company' && !this.expense.cardVerified) {
      this.showCompanyNote = true;
    } else {
      this.showCompanyNote = false;
    }
    return form;
  }

  resetFormAfterUncheckingFraud() {
    this.description.enable();

    this.amount.setValidators([
      Validators.required,
      CustomValidators.validAmount,
    ]);
    this.amount.updateValueAndValidity();

    this.vendor.setValidators([Validators.required]);
    this.vendor.updateValueAndValidity();

    this.transactionDate.setValidators([Validators.required]);
    this.transactionDate.updateValueAndValidity();

    this.jobCode.setValidators([Validators.required]);
    this.jobCode.updateValueAndValidity();

    this.type.enable();
    this.type.setValidators([Validators.required]);
    this.type.updateValueAndValidity();

    this.form.get('isBillable').enable();

    this.form.updateValueAndValidity();
  }

  onReceiptUploaded(id) {
    if (id && !this.expense.cardVerified) {
      this.receiptData.emit(id);
    } else if (!id && !this.expense.cardVerified) {
      this.receiptData.emit(this.expense.id);
    } else {
      this.receiptUploaded.emit(this.expense);
    }
  }

  onFileSelected(file) {

    if(file.file && file.newReceipt) {
      this.store$
      .pipe(
        select(selectReceiptData),
        takeUntil(this.unsubscribe$),
        tap((receiptData: Partial<Expense>) => {
          if (receiptData) {
            this.receiptDataOnUpload = receiptData;
            this.setFormValueFromReceipt(receiptData);
            this.receiptData.emit({
              ...file,
              token: receiptData.token
            });
          }
        })
      )
      .subscribe();
    } else {
      this.receiptData.emit({...file});
    }
    this.fileData = file;
  }

  onRemoveReceipt() {
    this.form.get('receiptRemoved').setValue(true);
    this.isOcrMismatch = false;
    this.form.get('ocrMismatch').setValue(this.isOcrMismatch);
    this.removeReceipt.emit({ id: this.expense.id });
  }

  onRotateReceipt({ angle }) {
    this.rotateReceipt.emit({ id: this.expense.id, angle });
  }

  onDismiss(dismiss) {
    this.expenseService.checkForDismissChanges(this, dismiss, 'edit');
  }

  onAdditionalDetailsClick() {
    this.showAdditionalDetails = !this.showAdditionalDetails;
  }

  public onReceiptResetForm() {
    this.resetForm.emit(this.form);
  }

  public onReceiptSaveForm() {
    this.saveForm.emit();
  }

  setFormValueFromReceipt(receiptData){
    // if receipt data is empty do nothing
    if(receiptData && Object.keys(receiptData).length === 0){
      return
    }
    const dollars = this.convertToDollarsPipe.transform(receiptData.amount * 100);
    const updatedAmount = this.currencyPipe.transform(dollars);
    if (this.expense.paymentType !== 'company') {
      this.isProgrammaticChange = true;
      this.form.patchValue({
        vendor: receiptData.vendor,
        address: receiptData.address,
        transactionDate: receiptData.transactionDate
      });
      this.isProgrammaticChange = false;
    }
    // If the expense is not company and not itemized expense only then update amount
    if (this.expense.paymentType !== 'company' && this.expense.parent === null) {
      this.isProgrammaticChange = true;
      this.form.patchValue({
        amount: updatedAmount,
      });
      this.isProgrammaticChange = false;
    }

    const amountInCents = dollars * 100;
    // Set ocr mismatch value only when editing Company type expense and not itemized expense
    if((this.expense.paymentType === 'company' && this.expense.parent === null) &&
    ((receiptData.amount && (this.expense.amount !== amountInCents)) ||
      this.compareDates(this.expense.transactionDate, receiptData.transactionDate)
    )
    ) {
      this.isOcrMismatch = true;
    }
    this.form.get('ocrMismatch').setValue(this.isOcrMismatch);
  }

  OnValueChange(event,typeOfChange) {
    // if the changes are applied through patching form values do nothing
    // since this function is used in the form element in html this is
    // triggered when ever the form is updated programmatically
    if(this.isProgrammaticChange) {
      return
    }
    // set the transaction date in the form when date is typed
    // instead of selecting from picker
    if(typeOfChange === 'date') {
      const formattedDate = event.utc().format("YYYY-MM-DD[T]HH:mm:ss.SS");
      this.transactionDate.setValue(formattedDate);
    }
    // If the receipt is just uploaded and the amount or date is edited nad the expense is not itemized
    if(
      this.receiptDataOnUpload &&
      this.receiptDataOnUpload.amount
    ) {
      this.setOCrMismatchValue(this.receiptDataOnUpload)
    } else { // if the amount or date is edit on existing expense
      this.checkOcrMismatch();
    }
  }

  checkOcrMismatch() {
    this.store$
    .pipe(
      select(selectReceiptDataByToken),
      take(1),
      tap((receiptData) => {
        const token = this.expense.token
        if (!receiptData || (receiptData && Object.keys(receiptData).length === 0)
          && (token !== undefined && token !== 'undefined' && token !== null)
        ) {
          this.store$.dispatch(DraftActions.getReceiptDataByToken({ token: token }));
        }
      }),
      switchMap(() =>
        this.store$.pipe(
          select(selectReceiptDataByToken),
          takeUntil(this.unsubscribe$),
          tap((receiptData: Partial<Expense>) => {
            // Set the ocr mismatch message for not itemized expenses
            if(this.expense.parent === null){
              this.setOCrMismatchValue(receiptData);
            }
          })
        )
      )
    )
    .subscribe();

    this.form.get('ocrMismatch').setValue(this.isOcrMismatch);
  }

  setOCrMismatchValue(receiptData) {
    if (receiptData && Object.keys(receiptData).length > 0) {
      const formAmountInCents = this.utils.dollarsToCents(this.amount.value);
      const receiptAmtInCents = receiptData.amount * 100;
      if(receiptData.transactionDate) {
        const formDate = this.transactionDate.value;
        const receiptDate = receiptData.transactionDate.toString();
        const isMismatchAmount = receiptAmtInCents !== formAmountInCents;
        const isMismatchDate = this.compareDates(formDate, receiptDate);

        this.isOcrMismatch = (isMismatchAmount || isMismatchDate);
      } else {
        this.isOcrMismatch = false;
      }
    }
    this.form.get('ocrMismatch').setValue(this.isOcrMismatch);
  }

  public onTravelTicketAction(expense: Expense) {
    this.updateForm(expense);
  }

  public onMatchExpense(expenseId: number) {
    this.apiService.getOneExpense(expenseId).subscribe((expense) => {
      this.updateForm(expense);
    });
  }

  updateForm(expense: Partial<Expense>) {
    this.form.get('isBillable').setValue(expense.isBillable);
    // Update job code to TDR customcase approval job code if custom case does not exist
    if(
      expense.salesforceId &&
      expense.salesforceId !== this.salesforceId.value
    ) {
      const filteredCases = this.salesforceCases.filter((c) => c.id === expense.salesforceId);
      // Find the matching jobCode record if custom case ahs approval jobCode
      if(filteredCases.length > 0 && filteredCases[0].expense_approval_job_code !== null) {
        const approval_jobCode = this.jobCodes.filter
          ((j) => j.id === filteredCases[0].expense_approval_job_code
        );
        // if the approval jobCode is part of the dropdown assign jobCode to expense
        if(approval_jobCode.length > 0) {
          this.jobCode.setValue(approval_jobCode[0].id);
        }
      } else if(expense.jobCode){
        this.form.get('jobCode').setValue(expense.jobCode);
      }
      this.form.get('salesforceId').setValue(expense.salesforceId);
    }
    // Use TDR jobCode if custom case does not exist and TDR does not have custom case either.
    else if(expense.jobCode && !this.salesforceId.value){
      this.form.get('jobCode').setValue(expense.jobCode);
    }


    this.form.get('description').setValue(expense.description);
    this.form.get('travelTicket').setValue(expense.travelTicket);
    this.form.get('travelTicketDetail').setValue(expense.travelTicketDetail)
    let expenseCopy = { ...expense } as any;
    if (expenseCopy && expenseCopy.type && expenseCopy.type.id) {
      this.form.get('type').setValue(expenseCopy.type.id);
    } else {
      this.form.get('type').setValue(expense.type);
    }
    if (expense.image) {
      this.expense.image = expense.image;
      this.expense.hasReceipt = true;
    }
  }

  public compareDates(expenseDate: string, receiptDate: string): boolean {
    // Parse and format the dates to MM/DD/YYYY using moment
    const d1 = moment.utc(expenseDate).format('MM/DD/YYYY');
    const d2 = moment.utc(receiptDate).format('MM/DD/YYYY');

    // Calculate the difference in days
    const dayDiff = moment(d1, 'MM/DD/YYYY').diff(moment(d2, 'MM/DD/YYYY'), 'days');

    // Return true if the difference in days is greater than 4
    return dayDiff > 4 || dayDiff < 0 ;
  }
}
