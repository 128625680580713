<div class="table-heading">
  <div
    class="toggle-btn"
    (click)="toggleTable()"
  >
    <span class="toggle-icon">
      <ems-base-icon icon-prepend
      iconName="arrow_circle_right"></ems-base-icon>
    </span>
    <span class="toggle-text">Match to an existing expense</span>
    <div class="chevron-right-icon" [ngClass]="{ 'chevron-down-icon': isTableVisible }">
      <ems-base-icon iconName="chevron-right"></ems-base-icon>
    </div>
  </div>
</div>
<table
  mat-table
  [dataSource]="displayedData"
  class="match-table"
  *ngIf="isTableVisible"
>
  <!-- Date Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.transactionDate | date: 'MM/dd/yy' }}
    </td>
  </ng-container>

  <!-- Vendor Column -->
  <ng-container matColumnDef="vendor">
    <th mat-header-cell *matHeaderCellDef>Vendor</th>
    <td mat-cell *matCellDef="let element">{{ element.vendor }}</td>
  </ng-container>


  <!-- Amount Column -->
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
  </ng-container>

  <!-- JobCode Column -->
  <ng-container matColumnDef="jobCode">
    <th mat-header-cell *matHeaderCellDef>Job Code</th>
    <td mat-cell *matCellDef="let element">{{ element.jobCode }}</td>
  </ng-container>

  <!-- Description Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let element">{{ element.description }}</td>
  </ng-container>

  <!-- Assign Column -->
  <ng-container matColumnDef="assign">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button 
        class="assign-btn"
        (click)="assignExpense(element)"
      >
        <ems-base-icon
          icon-prepend
          iconName="arrow_circle_right"
        ></ems-base-icon>
      </button>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="match-table-header"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="match-table-row"
  ></tr>
</table>
